body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.ant-list-item{
  cursor: pointer;
  /*margin: 0 10px;*/
}
header{
  /*background: #089ce4 !important;*/
  background: linear-gradient(90deg,#00c4cc,#7d2ae8)!important;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0px;
}
.list{
  margin: -5px 10px 10px 10px;
}
