.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.ant-list-item{
  cursor: pointer;
  /*margin: 0 10px;*/
}
header{
  /*background: #089ce4 !important;*/
  background: linear-gradient(90deg,#00c4cc,#7d2ae8)!important;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0px;
}
.list{
  margin: -5px 10px 10px 10px;
}